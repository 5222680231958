






























































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { BonusService } from '@/services/bonus-service';
import bonuseUpload from './compoennts/bonuseUpload.vue';
@Component({
  components: {
    bonuseUpload
  }
})
export default class wechatImageText extends Vue {
  public form: any = {
    size: 10,
    current: 1,
    total: 0
  };
  public formInfo :any = {

  };
  public labelPosition: String = 'top';
  public title:string ='';
  private tableData: any[] = [];
  public failReason:any[] = [];
  public loading: Boolean = true;
  public kindshow: Boolean = false;
  public dialogVisible: boolean = false;
  private currentRow: any = null;
  public rules: any = {
    msgKey: [
            { required: true, message: '请输入消息key', trigger: ['change','blur'] }],
    msgMsg: [
            { required: true, message: '请输入消息', trigger: ['change','blur'] }
          ],
  }
  @Inject(BonusService) private bonusService!: BonusService;

  public async created(): Promise<void> {
    await this.getList();
  }

  public search() {
    this.form.current = 1;
    this.getList()
  }
  //分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }

  //获取数据源
  public async getList() {
    this.loading = true;
    const res = await this.bonusService.configMsgList(this.form);
    this.loading = false;
    let data: any = res.records
    this.tableData = data
    this.form.total = res.total;
    this.form.size = res.size;
    this.form.current = res.current;
  }
  //新增
  public async handAdd(){
    this.title = '添加消息配置'
    this.formInfo = {};
    this.dialogVisible = true;
  }
  //删除
  private async handDelete(row: any): Promise<void> {
    let param = {
      id: row.id
    }
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      await this.bonusService.deleteConfigMsg(param);
      this.getList()
      this.$message({
        message: '删除成功',
        type: 'success'
      });
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }
  }
  //修改
  public async handUpdate(row: any){
    this.title = '修改消息配置'
    this.formInfo = JSON.parse(JSON.stringify(row));
    this.dialogVisible = true;
  }
  //保存
  public submitForm() : void{
      const addRef = (this.$refs.formInfo as any)
      addRef.validate( async (valid: any) => {
          if (valid) {
            if(this.formInfo.id == undefined){
              const res = await this.bonusService.addConfigMsg(this.formInfo);
              if(res){
                this.$message({
                message: '添加成功',
                type: 'success'
              });
              }
            }else{
              const res = await this.bonusService.updateConfigMsg(this.formInfo);
              if(res){
                this.$message({
                  message: '修改成功',
                    type: 'success'
                  });    
              }
            }
            this.dialogVisible = false;
            this.getList();
          } else {
            console.log('error submit!!');
            return false;
          }
      });
  }
  // 重置
  public reset() {
    this.form = {
      size: 10,
      current: 1,
      total: 0
    };
    this.search()
  }

}
